import type { SearchOptions } from '@algolia/client-search';
import { tags } from '@prisma/client';
import algoliasearch from 'algoliasearch';
import { Tag } from '../interfaces/tags';
import { AlgoliaMedia } from '@/interfaces/algolia';
import _ from 'lodash';

export interface AlgoliaRankingUpdatePayload {
  objectID: string;
  score: number;
}

const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY!
);

const mutationClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!,
  process.env.ALGOLIA_ADMIN_API_KEY!
);

const tagsIndex = client.initIndex('tags');
const mutationTagsIndex = mutationClient.initIndex('tags');
const mediaIndex = client.initIndex('medias');

export async function searchTags(term: string, excludeTerms?: string[]) {
  const filters = excludeTerms?.map((term) => `NOT name:"${term}"`).join(' AND ');

  const params = {
    filters,
  };

  const { hits } = await tagsIndex.search<Tag>(term, params);

  return hits;
}

export async function addTagToAgolia(tag: tags) {
  const objectID = `App\\Models\\Tag::${tag.id}`;

  const res = await mutationTagsIndex.saveObject({
    ...tag,
    objectID,
    _tags: [objectID],
  });

  return res;
}

export async function clearAlgoliaCache() {
  await client.clearCache();
}

export async function searchAlgoliaMedias(term: string, params?: SearchOptions) {
  const publishedOnlyParams: SearchOptions = {
    filters: 'published = 1',
    ...params,
  };

  const res = await mediaIndex.search<AlgoliaMedia>(term, publishedOnlyParams);
  return res;
}

export function getObjectID(type: 'clips' | 'clip' | 'photo' | 'video', id: number) {
  const typeMap: Record<string, string> = {
    clip: 'Clips',
    clips: 'Clips',
    photo: 'Photo',
    video: 'Video',
  };

  return `App\\Models\\${typeMap[type]}::${id}`;
}

export async function syncAlgoliaRanking(payload: AlgoliaRankingUpdatePayload[]) {
  return await mutationClient.multipleBatch(
    payload.map((item) => {
      return {
        action: 'partialUpdateObjectNoCreate',
        indexName: 'medias',
        body: {
          objectID: item.objectID,
          score: item.score,
        },
      };
    })
  );
}
